import HomeBanner from './assets/homepage.jpg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={HomeBanner} className="App-logo" alt="logo" />
        <p>Hi! I'm CA Shenbagavalli KP</p>
        <p>This website is a Work in Progress, Here take some Love Instead!</p>
      </header>
    </div>
  );
}

export default App;
